






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import warehouseImportImportTypes from "@/constants/warehouseImportImportTypes";
import DataContainer from "@vuetifyx/common/DataContainer";
import downloadBase64File from "@/utils/downloadBase64File";

function makeBaseItemProperties(country) {
  return {
    marketId: {
      type: "XAutocomplete",
      attrs: {
        label: "Thị trường",
        "item-value": "_id",
        "item-text": "name",
        required: true,
        xOptions: {
          content: {
            async itemsLoader() {
              const { items } = await coreApiClient.call("markets", "findAll", {
                payload: JSON.stringify({
                  limit: -1,
                  filters: [
                    {
                      key: "countryId",
                      operator: "equal_to",
                      value: country._id,
                    },
                  ],
                }),
              });
              return items;
            },
          },
        },
      },
      rules: {
        equal_to: {},
      },
    },
    productId: {
      type: "XAutocomplete",
      attrs: {
        label: "Sản phẩm",
        required: true,
        "item-value": "_id",
        "item-text": "sku",
        xOptions: {
          content: {
            async itemsSuggestor(search, value, limit) {
              const { items } = await coreApiClient.call("products", "findAll", {
                payload: JSON.stringify({
                  search,
                  limit,
                  filters: [
                    {
                      key: "countryId",
                      operator: "equal_to",
                      value: country._id,
                    },
                    {
                      key: "_id",
                      operator: "equal_to",
                      value,
                    },
                  ],
                }),
              });
              return items;
            },
          },
        },
      },
    },
    count: {
      type: "number",
      attrs: {
        label: "Số lượng",
        required: true,
      },
    },
    failedCount: {
      type: "number",
      attrs: {
        label: "Số lượng hỏng / lỗi",
      },
    },
    note: {
      type: "textLarge",
      attrs: {
        label: "Ghi chú",
      },
    },
  };
}

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
      importItems: [],
      insertDialogContainer: new DataContainer(),
      userManager,
    };
  },
  methods: {
    makeTableOptions(country) {
      const self = this;
      const self2 = this;
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý nhập kho - Return",
          urlEnabled: true,
          search: {},
          displayFields: {
            purchaseTime: {
              text: "Ngày return",
              sortableable: true,
              options: {
                filter: "dateTime",
              },
            },
            warehouse: {
              text: "Kho",
              sortable: true,
              options: {
                subProp: "warehouse.name",
              },
            },
            importType: {
              text: "Loại nhập kho",
              sortable: true,
              options: {
                label(value) {
                  return warehouseImportImportTypes[value];
                },
              },
            },
            description: {
              text: "Mô tả",
            },
            product: {
              text: "Sản phẩm",
              sortable: true,
              options: {
                subProp: "product.sku",
              },
            },
            count: {
              text: "Số lượng",
              sortable: true,
              options: {
                filter: "number2",
              },
            },
            failedCount: {
              text: "Số lượng hỏng / lỗi",
              sortable: true,
              options: {
                filter: "number2",
              },
            },
            notReady: {
              text: "Update stock",
              options: {
                preTransform(value) {
                  return !value;
                },
                boolean: true,
              },
            },
            note: {
              text: "Ghi chú",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            createdByUser: {
              text: "User account",
              options: {
                subProp: "createdByUser.name",
              },
            },
            action: {},
          },
          filters: {
            filters: {
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              purchaseTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày mua (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày mua (kết thúc)",
                    },
                  },
                },
              },
              productId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          topActionButtons: {
            import: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  time: {
                                    type: "XDateTimePicker",
                                    attrs: {
                                      label: "Ngày return",
                                      required: true,
                                    },
                                  },
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "warehouseImports",
                                  "importReturnPrepare",
                                  {
                                    countryId: country._id,
                                    time: data.time,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();

                              self2.importItems = result.items;
                              self2.insertDialogContainer.value.show();

                              downloadBase64File(result.downloadableBuffer, "import_warehouse_return.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insert: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                  content: {
                    content: {
                      attrs: {
                        xOptions: {
                          content: {
                            fields: {
                              items: {
                                ext: {
                                  defaultValue() {
                                    return self.importItems;
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  on: {
                    xHidden() {
                      self.importItems = [];
                    },
                  },
                  ext: {
                    container: self.insertDialogContainer,
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("warehouseImports", `@/exportReturn`, findAllOptions);
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker: (item) => item.orderId || (item.product && item.product.sku),
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin", "vd", "vdl"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: country._id,
            });
            options.filters.push({
              key: "importType",
              operator: "equal_to",
              value: "return",
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("warehouseImports", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              item.countryId = country._id;
              item.importType = "return";
              return await coreApiClient.call("warehouseImports", "createMany", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "warehouseImports",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("warehouseImports", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: Object.assign(
              {
                warehouseId: {
                  type: "XAutocomplete",
                  attrs: {
                    label: "Kho",
                    required: true,
                    "item-value": "_id",
                    "item-text": "name",
                    xOptions: {
                      content: {
                        async itemsLoader() {
                          const { items } = await coreApiClient.call("warehouses", "findAll", {
                            payload: JSON.stringify({
                              limit: -1,
                              filters: [
                                {
                                  key: "countryId",
                                  operator: "equal_to",
                                  value: country._id,
                                },
                              ],
                            }),
                          });
                          return items;
                        },
                      },
                    },
                  },
                  ext: {
                    colLength: 4,
                  },
                },
                purchaseTime: {
                  type: "XDateTimePicker",
                  attrs: {
                    label: "Ngày return",
                    required: true,
                  },
                  ext: {
                    colLength: 4,
                  },
                },
                description: {
                  type: "textLarge",
                  attrs: {
                    label: "Mô tả",
                  },
                },
                readyType: {
                  type: "select",
                  attrs: {
                    label: "Cập nhật stock",
                    items: [
                      { value: "", text: "Trống" },
                      { value: "ready", text: "Update stock" },
                      { value: "notReady", text: "Không update stock" },
                    ],
                  },
                  ext: {
                    colLength: 4,
                  },
                },
                items: {
                  type: "XArrayInput",
                  attrs: {
                    label: "Danh sách đơn hàng",
                    xOptions: {
                      content: {
                        itemLabel: (item) => item.orderId || "[Trống]",
                        itemProperties: Object.assign({}, makeBaseItemProperties(country)),
                        template: {
                          formOptions: {
                            content: {
                              colLength: 4,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              makeBaseItemProperties(country)
            ),
            insertForm: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              content: {
                fieldNames: ["warehouseId", "purchaseTime", "readyType", "description", "items"],
              },
            },
            editForm: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              content: {
                fieldNames: ["warehouseId", "marketId", "purchaseTime", "description", "note", "count", "failedCount"],
              },
            },
            delete: userManager.checkRole(["admin", "vd", "vdl"]) && {
              confirmDisplayField: "_id",
            },
          },
        },
      };
    },
  },
  async created() {
    const country = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    this.tableOptions = this.makeTableOptions(country);
  },
});
